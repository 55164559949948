@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  color: #111;
  background-color: white;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  /* font-family: "Lato", sans-serif; */
  font-weight: 400;
  font-style: normal;
}

.new-icon {
  position: absolute;
  top: 4.8%;
  right: 10px;
}

.hero-section {
  background-size: cover;
  background-position: center;
  color: #383838;
  width: 100%;
  height: 70vh;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.svg-container {
  margin-top: 120px; /* Optional: Adjust if needed */
  height: 250px;
  width: 100%;
  font-weight: 500;
}

.about {
  width: 100%;
  height: 100vh;
}

#to-top {
  transition: all 0.5s;
}
/* 
.footer-box {
  background-color: #000000;
  
} */

.name {
  font-weight: 400;
  font-family: "Times New Roman", Times, serif;
}

.string {
  height: 200px;
  width: 1px;
  background-color: #111;
}

.project {
  width: 100%;
  height: 100%;
  /* background-color: blue; */
  box-shadow: #000000;
  margin: 50px 0px 50px 0px;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
}
/* // Add this to your global CSS or Tailwind config */
@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.animate-marquee {
  animation: marquee 30s linear infinite;
}

.project h1 {
  font-family: gilroy, Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: 400;
}

.experience-title {
  font-family: gilroy, Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: 400;
}
#nav-links {
  position: relative;
  color: #000;
  text-decoration: none;
}

#nav-links:hover {
  color: #000;
}

#nav-links::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.3s ease;
}

.bg-svg {
  background-image: url("data:image/svg+xml,Csvg xmlns='http://www.w3.org/2000/svg' width='651' height='651' viewBox='0 0 200 200'ECdefsEClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='88' y1='88' x2='0' y2='0'ECstop offset='0' stop-color='%2387846d'/ECstop offset='1' stop-color='%23dfdbc2'/EC/linearGradientEClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='75' y1='76' x2='168' y2='160'ECstop offset='0' stop-color='%23868686'/ECstop offset='0.09' stop-color='%23ababab'/ECstop offset='0.18' stop-color='%23c4c4c4'/ECstop offset='0.31' stop-color='%23d7d7d7'/ECstop offset='0.44' stop-color='%23e5e5e5'/ECstop offset='0.59' stop-color='%23f1f1f1'/ECstop offset='0.75' stop-color='%23f9f9f9'/ECstop offset='1' stop-color='%23FFFFFF'/EC/linearGradientECfilter id='c' x='0' y='0' width='200%25' height='200%25'ECfeGaussianBlur in='SourceGraphic' stdDeviation='12' /EC/filterEC/defsECpolygon fill='url(%23a)' points='0 174 0 0 174 0'/ECpath fill='%23000' fill-opacity='0.2' filter='url(%23c)' d='M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z'/ECpath fill='url(%23b)' d='M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z'/EC/svgE");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: top left;
}


.resume .overlay {
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.73) 17%,
    rgba(0, 0, 0, 0.66) 35%,
    rgba(0, 0, 0, 0.55) 62%,
    rgba(0, 0, 0, 0.4) 100%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 0.8)),
    color-stop(17%, rgba(0, 0, 0, 0.73)),
    color-stop(35%, rgba(0, 0, 0, 0.66)),
    color-stop(62%, rgba(0, 0, 0, 0.55)),
    color-stop(100%, rgba(0, 0, 0, 0.4))
  );
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.73) 17%,
    rgba(0, 0, 0, 0.66) 35%,
    rgba(0, 0, 0, 0.55) 62%,
    rgba(0, 0, 0, 0.4) 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.73) 17%,
    rgba(0, 0, 0, 0.66) 35%,
    rgba(0, 0, 0, 0.55) 62%,
    rgba(0, 0, 0, 0.4) 100%
  );
  /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.73) 17%,
    rgba(0, 0, 0, 0.66) 35%,
    rgba(0, 0, 0, 0.55) 62%,
    rgba(0, 0, 0, 0.4) 100%
  );
  /* IE10+ */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.73) 17%,
    rgba(0, 0, 0, 0.66) 35%,
    rgba(0, 0, 0, 0.55) 62%,
    rgba(0, 0, 0, 0.4) 100%
  );
  /* W3C */
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#cc000000', endColorstr='#66000000', GradientType=0);
  /* IE6-9 */
  width: 100%;
  height: 100%;
  background-attachment: fixed;
}

.footer-bg {
  box-shadow: 0px -21px 38px 27px rgba(0, 0, 0, 0.1);
}

